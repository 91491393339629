import React from 'react';
import SupplementNavigation from '../components/form/supplementNavigation';

class Supplement extends React.Component {
  render() {
    return <SupplementNavigation></SupplementNavigation>;
  }
}

export default Supplement;
