import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

const InsuranceProviders = [
  'AARP',
  'Aetna',
  'American Continental',
  'American Retirement Life',
  'Anthem Blue Cross Blue Shield',
  'Assurant',
  'Bankers Fidelity',
  'Bankers Life and Casualty',
  'Blue Cross and Blue Shield',
  'Central States Indemnity',
  'Cigna',
  'Combined Insurance',
  'Continental Life',
  'Coventry Health Care',
  'EmblemHealth',
  'Equitable',
  'ForeThought',
  'Gerber',
  'Government Personnel Mutual (GPM)',
  'Health Net',
  'HealthSpring',
  'Heartland National',
  'Highmark',
  'Humana',
  'Individual Assurance Company (IAC)',
  'Kaiser',
  'Loyal American',
  'Manhattan Life',
  'Medico',
  'Molina Healthcare',
  'Mutual of Omaha',
  'New Era',
  'Omaha Insurance',
  'Oxford Life',
  'Philadelphia American',
  'SilverScript',
  'Standard Life and Casualty',
  'State Farm',
  'Stonebridge',
  'Thrivent Financial',
  'TransAmerica',
  'United American',
  'United of Omaha',
  'United World',
  'UnitedHealthcare',
  'Universal American',
  'WellCare',
  'WellPoint',
  'Other',
  'I’m Not Sure',
];

class SelectInsuranceProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProvider: props.data ? props.data : '',
    };
  }

  getProvider = () => {
    return this.state.selectedProvider;
  };

  onChange = (event) => {
    this.setState({
      selectedProvider: event.target.value,
    });
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render() {
    const { selectedProvider } = this.state;
    const { required, idPrefix } = this.props;
    return (
      <>
        <Form.Group>
          <Form.Control
            id={
              idPrefix ? `${idPrefix}-insurance-provider` : 'insurance-provider'
            }
            as='select'
            onChange={this.onChange}
            value={selectedProvider}
            required={required}
          >
            <option disabled value=''>
              Choose Your Provider
            </option>
            {InsuranceProviders.map((provider, index) => {
              return (
                <option value={provider} key={index}>
                  {provider}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      </>
    );
  }
}

SelectInsuranceProvider.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool,
  data: PropTypes.string,
  idPrefix: PropTypes.string,
};

export default SelectInsuranceProvider;
