import { Button, Col, Form, Row } from 'react-bootstrap';

import FormLayout from '../layout/formLayout';
import FormUtils from '../../utils/formUtils';
import MaskedInput from 'react-maskedinput';
import PropTypes from 'prop-types';
import React from 'react';
import SelectState from '../subComponents/selectState';
import YesNoQuestion from '../subComponents/yesNoQuestion';

class Step8 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMail: props.data.mail_information
        ? props.data.mail_information === 'yes'
        : '',
      street: props.data.street ? props.data.street : '',
      city: props.data.city ? props.data.city : '',
      state: props.data.state ? props.data.state : '',
      zip: props.data.zip ? props.data.zip : '',
      additionalPhoneNo: props.data.additional_phone
        ? props.data.additional_phone
        : '',
      inquiringMsg: props.data.additional_info
        ? props.data.additional_info
        : '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const {
      sendMail,
      additionalPhoneNo,
      inquiringMsg,
      street,
      city,
      state,
      zip,
    } = this.state;
    const data = {
      mail_information: sendMail ? 'yes' : 'no',
      street,
      city,
      state,
      zip,

      additional_phone: additionalPhoneNo,
      additional_info: inquiringMsg,
    };
    this.props.onSubmit(data);
  };

  onSendMailChange = (value) => {
    this.setState({ sendMail: value });
  };

  onStateChange = (value) => {
    this.setState({ state: value });
  };

  render() {
    const { sendMail, street, city, zip, inquiringMsg } = this.state;

    return (
      <FormLayout step='STEP_8' formOnSubmit={this.onSubmit} isForm={true}>
        <Row className='align-items-center quote-form-row'>
          <Col xs={12} sm={12}>
            <h3>Would you like rates &amp; information mailed to you?</h3>
          </Col>
          <Col xs={12} sm={12}>
            <YesNoQuestion
              idPrefix='mail-rates'
              defaultValue={sendMail}
              onChange={this.onSendMailChange}
            />

            {sendMail === true && (
              <>
                <div className='sub-form-wrap-row-outer sm-mb50 xs-mb30'>
                  <Row className='sub-form-wrap-row'>
                    <Col xs={12} sm={12}>
                      <Form.Group>
                        <Form.Control
                          id='mail-street'
                          type='text'
                          placeholder='Street'
                          value={street}
                          onChange={(event) => {
                            this.setState({
                              street: event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.setState({
                              street: FormUtils.cleanString(event.target.value),
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                      <Form.Group>
                        <Form.Control
                          id='mail-city'
                          type='text'
                          placeholder='City'
                          value={city}
                          onChange={(event) => {
                            this.setState({
                              city: event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.setState({
                              city: FormUtils.cleanString(event.target.value),
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                      <SelectState onChange={this.onStateChange} />
                    </Col>
                    <Col xs={12} sm={4}>
                      <Form.Group>
                        <Form.Control
                          id='mail-zip'
                          type='text'
                          placeholder='ZIP Code'
                          value={zip}
                          onChange={(event) => {
                            this.setState({
                              zip: event.target.value,
                            });
                          }}
                          pattern='[0-9]{5}'
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </Col>
          <Col xs={12} sm={12}>
            <hr className='form-hr mb-0 mt-0' />
          </Col>
          <Col xs={12} sm={12}>
            <div className='sub-form-wrap-row-outer'>
              <Row className='sub-form-wrap-row'>
                <Col xs={12} sm={12}>
                  <p className='p20 que-text'>
                    If there&apos;s a better number other than the one provided,
                    please enter below. (Additional phone no)
                  </p>
                </Col>
                <Col xs={12} sm={12}>
                  <Form.Group>
                    <Form.Control
                      id='additional-phone'
                      as={MaskedInput}
                      mask='(111) 111-1111'
                      placeholder='(123) 456-7890'
                      type='tel'
                      onChange={(event) => {
                        this.setState({
                          additionalPhoneNo: event.target.value,
                        });
                      }}
                      pattern='^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='sub-form-wrap-row xs-mt5 xs-mb10'>
                <Col xs={12} sm={12}>
                  <p className='p20 que-text'>
                    If you are inquiring on behalf of someone or you have
                    additional information that you would like to include,
                    please enter below.
                  </p>
                </Col>
                <Col xs={12} sm={12}>
                  <Form.Group>
                    <Form.Control
                      id='additional-info'
                      as='textarea'
                      placeholder='Additional Info'
                      rows='3'
                      maxLength={499}
                      value={inquiringMsg}
                      onChange={(event) => {
                        this.setState({
                          inquiringMsg: event.target.value,
                        });
                      }}
                      onBlur={(event) => {
                        this.setState({
                          inquiringMsg: FormUtils.cleanString(
                            event.target.value
                          ),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} sm={12}>
            <Row className='align-items-center quote-form-row md-mt10 mt20 xs-mt0'>
              <Col xs={12} sm={12}>
                <Button
                  id='step-8-submit-btn'
                  type='submit'
                  variant='btn btn-danger-custom w-100 mt20 xs-mb15'
                >
                  <span>Reload My Rates</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </FormLayout>
    );
  }
}

Step8.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
export default Step8;
