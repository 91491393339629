import { Button, Col, Form, Row } from 'react-bootstrap';
import {
  GroupPlans,
  MedicareAdvantagePlans,
  MedigapPlans,
  OtherInsuranceWithProviders,
} from '../../constants/DropdownConstants';

import FormLayout from '../layout/formLayout';
import FormUtils from '../../utils/formUtils';
import MandatoryLabel from '../subComponents/mandatoryLabel';
import PropTypes from 'prop-types';
import React from 'react';
import SelectInsuranceProvider from '../subComponents/selectInsuranceProvider';
import SelectInsuranceType from '../subComponents/selectInsuranceType';
import SelectMonth from '../subComponents/selectMonth';
import SelectPlan from '../subComponents/selectPlan';
import SelectYear from '../subComponents/selectYear';
import YesNoQuestion from '../subComponents/yesNoQuestion';

class Step7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrolledInMedicareB:
        props.data.enrolled_medicare_part_b !== ''
          ? props.data.enrolled_medicare_part_b === 'yes'
          : '',
      enrolledMonth: props.data.month_enrolled ? props.data.month_enrolled : '',
      enrolledYear: props.data.year_enrolled ? props.data.year_enrolled : '',
      coveredByOtherIns:
        props.data.covered_by_other !== ''
          ? props.data.covered_by_other === 'yes'
          : '',
      otherInsuranceType: props.data.other_insurance_type
        ? props.data.other_insurance_type
        : '',
      insurancePlan: props.data.plan_type ? props.data.plan_type : '',
      otherPlanType: props.data.other_plan_type
        ? props.data.other_plan_type
        : '',
      insuranceProvider: props.data.insurance_provider
        ? props.data.insurance_provider
        : '',

      formValidated: false,
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      FormUtils.scrollToTop();
      this.setState({ formValidated: true });
      event.stopPropagation();
    } else {
      const {
        enrolledInMedicareB,
        enrolledMonth,
        enrolledYear,
        coveredByOtherIns,
        otherInsuranceType,
        insurancePlan,
        otherPlanType,
        insuranceProvider,
      } = this.state;
      const data = {
        spouse_enrolled_medicare_part_b: enrolledInMedicareB ? 'yes' : 'no',
        spouse_covered_by_other: coveredByOtherIns ? 'yes' : 'no',

        spouse_month_enrolled: enrolledMonth,
        spouse_year_enrolled: enrolledYear,

        spouse_other_insurance_type: otherInsuranceType,
        spouse_plan_type: insurancePlan,
        spouse_other_plan_type: otherPlanType,
        spouse_insurance_provider: insuranceProvider,
      };

      this.props.onSubmit(data);
    }
  };

  handleCoveredByOtherIns = (value) => {
    this.setState({ coveredByOtherIns: value });
  };

  handleEnrolledInMedicareB = (value) => {
    this.setState({ enrolledInMedicareB: value });
  };

  onEnrolledMonthChange = (value) => {
    this.setState({ enrolledMonth: value });
  };

  onEnrolledYearChange = (value) => {
    this.setState({ enrolledYear: value });
  };

  onInsuranceTypeChange = (value) => {
    this.setState({ otherInsuranceType: value });
  };

  onInsurancePlanChange = (value) => {
    this.setState({ insurancePlan: value });
  };

  onInsuranceProviderChange = (value) => {
    this.setState({ insuranceProvider: value });
  };

  render() {
    const {
      enrolledInMedicareB,
      enrolledMonth,
      enrolledYear,
      coveredByOtherIns,
      otherInsuranceType,
      insurancePlan,
      otherPlanType,
      insuranceProvider,
      formValidated,
    } = this.state;

    return (
      <FormLayout step='STEP_7'>
        <Form
          id='step-7-form'
          noValidate
          onSubmit={this.onSubmit}
          validated={formValidated}
        >
          <Row className='align-items-center quote-form-row'>
            <Col xs={12} sm={12}>
              <h3>Spouse/Partner Supplemental Information</h3>
            </Col>
            <Col xs={12} sm={12}>
              <p className='p20 que-text'>
                Are they currently enrolled in Medicare Part B?{' '}
                <MandatoryLabel size='regular' />{' '}
              </p>

              <YesNoQuestion
                idPrefix='spouse-enrolled-medicare-b'
                defaultValue={enrolledInMedicareB}
                onChange={this.handleEnrolledInMedicareB}
              />

              {enrolledInMedicareB !== '' && (
                <>
                  <div className='sub-form-wrap-row-outer'>
                    <Row className='sub-form-wrap-row'>
                      <Col xs={12} sm={12}>
                        <p className='p20 que-text'>
                          {enrolledInMedicareB
                            ? 'Enter the date enrolled into Medicare Part B.'
                            : 'When does their Medicare Part B take effect?'}
                        </p>
                        <p className='p16 mb15 mt-15'>
                          If you aren&apos;t sure of the actual date, please
                          make an estimate.
                        </p>
                      </Col>
                      <Col xs={12} sm={6}>
                        <SelectMonth
                          idPrefix='enrolled'
                          data={enrolledMonth}
                          onChange={this.onEnrolledMonthChange}
                          required={true}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <SelectYear
                          idPrefix='enrolled'
                          reverse={true}
                          data={enrolledYear}
                          onChange={this.onEnrolledYearChange}
                          required={true}
                        />
                      </Col>
                      <Col xs={12} sm={12}>
                        <hr className='form-hr' />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Col>

            <Col xs={12} sm={12}>
              <p className='p20 que-text'>
                Is your spouse/partner covered by other health insurance?{' '}
                <MandatoryLabel size='regular' />{' '}
              </p>
              <YesNoQuestion
                idPrefix='spouse-covered-other-insurance'
                defaultValue={coveredByOtherIns}
                onChange={this.handleCoveredByOtherIns}
              />
              {coveredByOtherIns && (
                <div className='sub-form-wrap-row-outer mb30'>
                  <Row className='sub-form-wrap-row'>
                    <Col xs={12} sm={12}>
                      <p className='p20 que-text'>
                        Please choose which type of insurance they currently
                        have
                      </p>
                    </Col>
                    <Col xs={12} sm={{ offset: 1, span: 10 }}>
                      <SelectInsuranceType
                        idPrefix='spouse'
                        data={otherInsuranceType}
                        onChange={this.onInsuranceTypeChange}
                        required={true}
                      />
                    </Col>

                    <Col xs={12} sm={{ offset: 1, span: 10 }}>
                      {otherInsuranceType ===
                      'Medicare Supplement (Medigap)' ? (
                        <SelectPlan
                          idPrefix='spouse'
                          plans={MedigapPlans}
                          onChange={this.onInsurancePlanChange}
                          required={true}
                          data={insurancePlan}
                        />
                      ) : otherInsuranceType ===
                        'Medicare Advantage (HMO, PPO, PFFS)' ? (
                        <SelectPlan
                          idPrefix='spouse'
                          plans={MedicareAdvantagePlans}
                          onChange={this.onInsurancePlanChange}
                          required={true}
                          data={insurancePlan}
                        />
                      ) : otherInsuranceType === 'Group' ||
                        otherInsuranceType === 'Individual Health' ? (
                        <SelectPlan
                          idPrefix='spouse'
                          plans={GroupPlans}
                          onChange={this.onInsurancePlanChange}
                          required={true}
                          data={insurancePlan}
                        />
                      ) : null}
                    </Col>
                  </Row>
                  {insurancePlan === 'Other' && (
                    <Row className='sub-form-wrap-row'>
                      <Col xs={12} sm={12}>
                        <p className='p20 que-text'>
                          Please indicate their OTHER plan type:
                        </p>
                        <Form.Group>
                          <Form.Control
                            id='spouse-other-plan-type'
                            placeholder='Your other plan type'
                            type='text'
                            onChange={(event) => {
                              this.setState({
                                otherPlanType: event.target.value,
                              });
                            }}
                            value={otherPlanType}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {OtherInsuranceWithProviders.includes(otherInsuranceType) && (
                    <Row className='sub-form-wrap-row xs-pt25'>
                      <Col xs={12} sm={12}>
                        <p className='p20 que-text'>
                          Who is their current health insurance provider?
                        </p>
                      </Col>
                      <Col xs={12} sm={12}>
                        <SelectInsuranceProvider
                          idPrefix='spouse'
                          onChange={this.onInsuranceProviderChange}
                          required={true}
                          data={insuranceProvider}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            </Col>

            <Col xs={12} sm={12}>
              <Row className='align-items-center quote-form-row'>
                <Col xs={12} sm={12}>
                  <Button
                    variant='btn btn-danger-custom w-100'
                    type='submit'
                    id='step-7-submit-btn'
                  >
                    <span>Next</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormLayout>
    );
  }
}

Step7.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
export default Step7;
