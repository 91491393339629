import { Form } from 'react-bootstrap';
import { OtherInsuranceOptions } from '../../constants/DropdownConstants';
import PropTypes from 'prop-types';
import React from 'react';

const InsuranceType = OtherInsuranceOptions;

class SelectInsuranceType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInsuranceType: props.data ? props.data : '',
    };
  }

  getInsuranceType = () => {
    return this.state.selectedInsuranceType;
  };

  onChange = (event) => {
    this.setState({
      selectedInsuranceType: event.target.value,
    });
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render() {
    const { selectedInsuranceType } = this.state;
    const { required, idPrefix } = this.props;
    return (
      <>
        <Form.Group>
          <Form.Control
            id={idPrefix ? `${idPrefix}-insurance-type` : 'insurance-type'}
            as='select'
            value={selectedInsuranceType}
            onChange={this.onChange}
            required={required}
          >
            <option disabled value=''>
              Choose Your Insurance Type
            </option>
            {InsuranceType.map((type, index) => {
              return (
                <option value={type} key={index}>
                  {type}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      </>
    );
  }
}

SelectInsuranceType.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool,
  data: PropTypes.string,
  idPrefix: PropTypes.string,
};

export default SelectInsuranceType;
